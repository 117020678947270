import type { SVGProps } from 'react';
const SvgRankingFill = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="#9EF22E"
      d="M7.85 5.88c0-.424 0-.636.062-.806.103-.284.328-.509.612-.612.17-.062.382-.062.807-.062h9.338c.425 0 .637 0 .807.062.284.103.509.328.612.612.062.17.062.382.062.806v5.695a6.15 6.15 0 1 1-12.3 0zM8.875 24.444a3.644 3.644 0 0 1 3.644-3.644h2.962a3.644 3.644 0 0 1 3.644 3.644.456.456 0 0 1-.456.456H9.331a.456.456 0 0 1-.456-.456"
    />
    <path
      stroke="#101828"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.757}
      d="M14 17.725a6.15 6.15 0 0 1-6.15-6.15V5.88c0-.424 0-.636.062-.806.103-.284.328-.509.612-.612.17-.062.382-.062.807-.062h9.338c.425 0 .637 0 .807.062.284.103.509.328.612.612.062.17.062.382.062.806v5.695a6.15 6.15 0 0 1-6.15 6.15m0 0V20.8m6.15-14.35h2.563c.477 0 .716 0 .904.078.251.104.45.304.555.555.078.188.078.427.078.904V8.5c0 .953 0 1.43-.105 1.82a3.08 3.08 0 0 1-2.174 2.175c-.391.105-.868.105-1.821.105M7.85 6.45H5.288c-.478 0-.717 0-.905.078-.251.104-.451.304-.555.555-.078.188-.078.427-.078.904V8.5c0 .953 0 1.43.105 1.82a3.08 3.08 0 0 0 2.174 2.175c.391.105.868.105 1.821.105m1.48 12.3h9.34a.456.456 0 0 0 .455-.456 3.644 3.644 0 0 0-3.644-3.644h-2.962a3.644 3.644 0 0 0-3.644 3.644c0 .252.204.456.456.456"
    />
  </svg>
);
export default SvgRankingFill;
