import type { SVGProps } from 'react';
const SvgRanking = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.757}
      d="M14 17.725a6.15 6.15 0 0 1-6.15-6.15V5.881c0-.424 0-.636.062-.806.103-.285.328-.51.612-.613.17-.062.382-.062.807-.062h9.338c.425 0 .637 0 .807.062.284.104.509.328.612.613.062.17.062.382.062.806v5.694a6.15 6.15 0 0 1-6.15 6.15m0 0V20.8m6.15-14.35h2.563c.477 0 .716 0 .904.078.251.104.45.304.555.555.078.189.078.427.078.905V8.5c0 .954 0 1.43-.105 1.821a3.08 3.08 0 0 1-2.174 2.175c-.391.104-.868.104-1.821.104M7.85 6.45H5.288c-.478 0-.717 0-.905.078-.251.104-.451.304-.555.555-.078.189-.078.427-.078.905V8.5c0 .954 0 1.43.105 1.821a3.08 3.08 0 0 0 2.174 2.175c.391.104.868.104 1.821.104m1.48 12.3h9.34a.456.456 0 0 0 .455-.455 3.644 3.644 0 0 0-3.644-3.645h-2.962a3.644 3.644 0 0 0-3.644 3.645c0 .251.204.455.456.455"
    />
  </svg>
);
export default SvgRanking;
